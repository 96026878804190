<template>
  <div>
    <input
      ref="reference"
      v-model="internalTime"
      type="text"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
      :class="$style.input"
    />
    <div
      ref="floating"
      v-if="showPopover"
      :class="$style.popover"
      :style="floatingStyles"
    >
      <div
        v-for="(_time, key) in times"
        :key="key"
        :ref="_time"
        @click.prevent="
          time = $moment
            .utc($moment().format('YYYY-MM-DD') + ' ' + _time)
            .format('HH:mm')
        "
        :data-test="'time_picker_set_' + _time"
      >
        <slot name="timeSuggestion" :timeLocal="_time">
          <button :class="$style.buttonTimeSuggestion">
            {{ _time }}
          </button>
        </slot>
      </div>
    </div>
  </div>
  <!-- <PopoverHelper>
    <template #button>
      <input v-model="time" type="text" />
      <div :class="$style.button">
        {{ time }}
      </div>
    </template>
    <template v-slot="{ close }">
      
    </template>
  </PopoverHelper> -->
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  watch: {
    time(val) {
      this.internalTime = val;
    },
    showPopover(val) {
      if (val) {
        setTimeout(() => {
          const utcTime = this.$moment.utc(
            this.$moment().format("YYYY-MM-DD") + " " + this.time,
          );

          const scrollToTime = utcTime
            .minute(Math.floor(utcTime.minute() / 15) * 15)
            .format("HH:mm");

          // console.log(scrollToTime, this.$refs);
          this.$refs[scrollToTime][0].scrollIntoView({
            behavior: "instant",
            block: "start",
          });

          // const roundedToQuarterHour = Math.round(
          //   this.$moment
          //     .utc(this.$moment().format("YYYY-MM-DD") + " " + this.time)
          //     .minute() / 15,
          // );

          // console.log("roundedToQuarterHour", roundedToQuarterHour);
          // const roundedTime = this.$moment.utc(this.time).format("HH:mm");
          // console.log("roundedTime", roundedTime);
        }, 0);
      }
    },
  },
  mounted() {
    this.internalTime = this.time;
  },
  computed: {
    time: {
      get() {
        return this.$moment
          .utc(this.$moment.utc().format("YYYY-MM-DD") + " " + this.modelValue)
          .local()
          .format("HH:mm");
      },
      set(val) {
        if (this.isValidTime(val)) {
          this.$emit(
            "update:modelValue",
            this.$moment(this.$moment.utc().format("YYYY-MM-DD") + " " + val)
              .utc()
              .format("HH:mm"),
          );
        }
      },
    },
    times() {
      return Array.from({ length: 24 }, (_, hour) => {
        return ["00", "15", "30", "45"].map((minute) => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    },
  },
  methods: {
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },

    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.time = this.internalTime;
      this.delayShowPopover(false);
    },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
  },
  data() {
    return {
      internalTime: "",
      showPopover: false,
    };
  },
};
</script>

<style module lang="scss">
.input {
  composes: input from "@/styles/form.module.scss";

  font-variant-numeric: tabular-nums;

  width: 56px;
  text-align: center;

  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
}
.popover {
  composes: panel from "@/styles/panels.module.scss";
  max-height: 20rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--ps-base-background-color);
  z-index: 10;
}

.buttonTimeSuggestion {
  @apply flex items-center gap-1;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
