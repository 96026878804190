<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div data-test="name_display" :class="['name_display']">
    <TextInput
      ref="text_input"
      v-model="entry.name"
      @onBlur="!entry.temp ? updateName(false) : null"
      @onEnter="(name) => $emit('onEnter', name)"
      :validation="(val) => val.trim()"
      @onKeyDown="(name, event) => $emit('onKeyDown', name, event)"
      @onKeyUp="onKeyUp"
      @onFocus="() => $emit('onFocus')"
      :clearOnEnter="entry.temp"
      :blurOnEnter="!entry.temp"
      :placeholder="placeholder ? placeholder : 'Unnamed'"
      :disabled="disabled"
      @click="(e) => (!disabled && !entry.temp ? e.stopPropagation() : null)"
      :color="'default' /* !['left', 'calendar'].includes(position) ? entry.color : 'default' */"
      :class="{
        [`${$style.textInput}`]: true,
        [`${$style.textInput_editable}`]: !disabled,
        [`${$style.textInput_temp}`]: entry.temp,
      }"
    />
  </div>
</template>

<script>
// 'cursor-pointer': disabled && position != 'template',
// 'text-3xl !font-bold leading-snug lg:text-4xl lg:leading-snug':
//   size == '4xl',
// 'text-2xl': size == '2xl',
// 'text-lg': size == 'lg',
// 'text-sm': size == 'sm',
// 'text-xs': size == 'xs',
// 'inline box-decoration-clone': !entry.temp,
// '!block truncate': position == 'calendar',
// '!-m-1 !px-1 !py-1 hover:!bg-neutral-100 dark:hover:!bg-neutral-800':
//   // $windowSize.width >= 768 &&
//   !entry.temp &&
//   ['center', 'bound', 'modal', 'template'].includes(position),
// 'inline box-decoration-clone': !entry.temp,
// '!-m-1 !px-1 !py-1':
//   // $windowSize.width >= 768 &&
//   !entry.temp &&
//   ['contextmenu', 'search', 'draggable', 'focus'].includes(position),
// 'font-semibold': position != 'left' && entry.output,
// '!m-0 !px-0 !py-0 ':
//   entry.temp ||
//   ['left', 'undo', 'calendar', 'command-palette'].includes(position), //||
// // $windowSize.width < 768,
// 'font-semibold': position != 'left' && entry.output,

// import { Addon } from "@/components/mixins/Addon";
// import { Filtering } from "@/components/mixins/Filtering";
import TextInput from "@/components/helpers/TextInput.vue";
// import { Coloring } from "../mixins/Coloring";

export default {
  // mixins: [Addon, Filtering, Coloring],
  components: { TextInput },
  props: {
    modelValue: null,
    entry: Object,
    disabled: Boolean,
    placeholder: String,
    position: String,
    renderType: String,
    size: {
      type: String,
      default: "md",
    },
  },

  data() {
    return {
      oldName: this.modelValue.name,
    };
  },
  methods: {
    onKeyUp(name, event) {
      this.$emit("onKeyUp", name, event);
      if (event.key === "Enter" && !this.entry.temp) {
        this.$refs.text_input.$el.blur();
      }
    },
    updateName(enter) {
      // if (this.entry.name.length > 0) {
      if (this.entry.temp) {
        if (enter) {
          //this.$emit("onEnter", this.entry.name);
        }
      } else {
        if (this.oldName != this.entry.name) {
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          });
          this.oldName = this.entry.name;
        }
      }
      // }
    },
  },
};
</script>

<style module lang="scss">
.textInput {
  // @apply break-word;
  font-size: var(--ps-name-display-font-size);
  line-height: var(--ps-name-display-line-height);
  font-weight: var(--ps-name-display-font-weight);
  color: var(--ps-name-display-color);
  display: inline;
  &_editable {
    cursor: text;
    padding: 0.125rem 0.25rem;
    margin: -0.125rem -0.25rem;
    border-radius: 0.25rem;
    @media (hover: hover) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.025);
      }
    }
  }
  &_temp {
    width: 100%;
    display: inline-block;
    @media (hover: hover) {
      &:hover {
        background-color: unset;
      }
    }
  }
}

@container calendarEventContent (height) {
  .textInput {
    display: inline-block;
  }
}
</style>
