<template>
  <div :class="$style.linksDisplay" data-test="entry_links_section">
    <div class="_hidden _sm: flex flex-wrap gap-2">
      <a
        v-for="(link, key) in links"
        v-show="link.id != output_entry_origin?.id"
        :key="key"
        @click.stop="(e) => onClick(link, e)"
        class="hidden items-center gap-1 text-xs first:flex"
        :class="[
          ` 
            ${$style.link}
            ${position == 'command-palette' ? '' : 'sm:flex'}
            `,
        ]"
        :data-test="'link_' + $slugify(link.name) + '_button'"
        :style="{
          '--color': link.color
            ? 'var(--ps-color-' + link.color + '-500)'
            : 'var(--ps-base-primary-color)',
          '--bg-color': link.color
            ? 'var(--ps-color-' + link.color + '-50)'
            : 'transparent',
          '--color-hover': link.color
            ? 'var(--ps-color-' + link.color + '-600)'
            : 'var(--ps-base-primary-color)',
          '--bg-color-hover': link.color
            ? 'var(--ps-color-' + link.color + '-100)'
            : 'transparent',
          '--dark-color': link.color
            ? 'var(--ps-color-' + link.color + '-300)'
            : 'var(--ps-base-primary-color)',
          '--dark-bg-color': link.color
            ? 'var(--ps-color-' + link.color + '-950)'
            : 'transparent',
          '--dark-color-hover': link.color
            ? 'var(--ps-color-' + link.color + '-200)'
            : 'var(--ps-base-primary-color)',
          '--dark-bg-color-hover': link.color
            ? 'var(--ps-color-' + link.color + '-900)'
            : 'transparent',
        }"
      >
        {{
          link.name
            ? link.name.length > 20
              ? link.name.slice(0, 17) + "..."
              : link.name
            : "Unnamed"
        }}
      </a>

      <MenuHelper
        v-if="links.length > 1"
        as="div"
        :class="[
          ` 
            ${position == 'command-palette' ? '' : 'sm:hidden'}
          `,
        ]"
      >
        <template #button>
          <div
            class="hidden items-center gap-1 rounded bg-neutral-50 px-1 py-0.5 text-xs text-neutral-500 first:flex hover:bg-neutral-100 hover:text-neutral-600 dark:bg-neutral-950 dark:text-neutral-400"
          >
            <span class="sr-only">Open options</span>
            + {{ links.length - 1 }}
          </div>
        </template>

        <MenuItem
          v-for="(link, key) in links"
          :key="key"
          v-slot="{ active }"
          class="first:hidden"
        >
          <a
            @click.stop="(e) => onClick(link, e)"
            :active="active"
            class="block"
            :class="[
              ` 
                      text-${link.color}-500
                      hover:text-${link.color}-600
                      rounded  px-4 py-2 text-sm
                    `,
            ]"
          >
            {{
              link.name.length > 20 ? link.name.slice(0, 17) + "..." : link.name
            }}
          </a>
        </MenuItem>
      </MenuHelper>
    </div>
  </div>
</template>

<script>
import { Routing } from "@/components/mixins/Routing";
export default {
  inject: {
    output_entry_origin: {
      default: {},
    },
  },
  mixins: [Routing],
  props: {
    modelValue: Object,
    position: String,
  },
  data() {
    return {
      entry: this.modelValue,
      // links: [],
    };
  },
  watch: {
    modelValue: function () {
      this.entry = this.modelValue;
      // this.loadLinks();
    },
    // "entry.links": function () {
    //   this.loadLinks();
    // },
  },
  // mounted() {
  //   this.loadLinks();
  // },
  computed: {
    links() {
      return this.entry.links
        .map((id) => this.$store.getters.entries.find((e) => e.id == id))
        .filter((e) => e);
      // .filter((e) => e.id != this.output_entry_origin?.id);
    },
  },

  methods: {
    // loadLinks() {
    //   this.links = this.entry.links
    //     .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //     .filter((e) => e);
    //   //.filter((e) => e.id != this.$route.params.id);
    // },
    onClick(entry, e) {
      if (!e.shiftKey) {
        this.navigateTo(entry);
      }
    },
  },
};
</script>

<style module lang="scss">
.linksDisplay {
  display: flex;
  align-items: center;
}
.link {
  @apply rounded px-1 py-0.5;
  background-color: var(--bg-color);
  color: var(--color);
  &:hover {
    background-color: var(--bg-color-hover);
    color: var(--color-hover);
  }
  &:where(:global(.dark), :global(.dark *)) {
    background-color: var(--dark-bg-color);
    color: var(--dark-color);
    &:hover {
      background-color: var(--dark-bg-color-hover);
      color: var(--dark-color-hover);
    }
  }
}
@container calendarEventContent (width < 384px) {
  .linksDisplay {
    display: none;
  }
}
</style>
import { link } from "original-fs";
