<template>
  <div v-if="routine" data-test="routine_settings">
    <PopoverHelper :teleport="true">
      <template v-slot:button>
        <button role="button" data-test="routine_settings_button">
          <slot name="button_routine_configure" :routine="routine">
            <ButtonComponent
              title="Configure Routine"
              :size="size"
              :variant="variant"
            >
              <ArrowPathRoundedSquareIcon class="w-4" />
              <span>
                {{ routine.target }}x
                <span class="capitalize"> {{ routine.frequency }} </span>
              </span>
            </ButtonComponent>
          </slot>
        </button>
      </template>
      <div class="flex min-w-48 flex-col items-stretch gap-y-2 p-2">
        <CalendarComponent
          :mode="
            routine.frequency == 'weekly'
              ? 'weeks'
              : routine.frequency == 'monthly'
              ? 'months'
              : 'days'
          "
          v-slot="{ date, span } = slotProps"
        >
          <button
            @click="
              $moment(date).isSameOrBefore($moment(), 'day')
                ? logASpan(
                    getDateSpan(routine.frequency, date),
                    this.routine.frequency,
                    true,
                  )
                : null
            "
            :class="{
              'border-green-500 ':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === 1,
              'border-red-500 ':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === 0,
              'border-neural-500':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === null,
              'cursor-normal pointer-events-none opacity-50': $moment(
                date,
              ).isAfter($moment(), 'day'),
            }"
            class="absolute inset-0 rounded-full border-2"
          >
            {{ $moment(date).format("DD") }}
            <span v-if="span">
              - {{ $moment(date).add(span, "days").format("DD") }}
            </span>
          </button>
        </CalendarComponent>

        <LabelHelper> Frequency </LabelHelper>
        <OptionPicker
          v-model="routine.frequency"
          :options="options"
          @update:modelValue="
            (val) => (routine = { ...routine, ...{ frequency: val } })
          "
          data-test="entry_routine_frequency"
        />
        <LabelHelper> Target </LabelHelper>
        <TextPicker v-model="target" data-test="entry_routine_target" />

        <ButtonComponent @click="routine = null" class="ml-auto">
          <TrashIcon class="w-4" />
          <span> Delete </span>
        </ButtonComponent>
      </div>
    </PopoverHelper>
  </div>

  <div v-else>
    <button
      @click="routine = addonBlueprint('routine', entry, this)"
      data-test="entry_routine_create"
    >
      <slot name="button">
        <ButtonComponent
          title="Add a routine"
          :size="size"
          :variant="variant"
          :color="color"
        >
          <IconHelper name="repeat" size="20" />
          <span v-if="variant != 'round'">Routine</span>
        </ButtonComponent>
      </slot>
    </button>
  </div>
</template>

<script setup>
import { addonBlueprint } from "@/addonBlueprint";
import { ArrowPathRoundedSquareIcon } from "@heroicons/vue/24/outline";
</script>
<script>
import moment from "moment";
import { Coloring } from "../mixins/Coloring";
import { getDateSpan } from "@/datesHelper";
import { Routining } from "../mixins/Routining";

import TextPicker from "@/components/builders/TextPicker.vue";
import OptionPicker from "@/components/builders/OptionPicker.vue";
import { Addon } from "../mixins/Addon";

export default {
  data() {
    return {
      open: false,
      getDateSpan: getDateSpan,
      options: ["daily", "weekly", "monthly"],
    };
  },
  components: { TextPicker, OptionPicker },
  mixins: [Coloring, Routining, Addon],
  computed: {
    routine: {
      get: function () {
        return this.modelValue;
      },
      set(routine) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.routine = routine;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },

    target: {
      get() {
        return [this.routine.target];
      },
      set(val) {
        this.routine = { ...this.routine, ...{ target: val[0] } };
      },
    },
    slots() {
      switch (this.routine.frequency) {
        case "daily":
          return Array.from({ length: 7 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-i, "days"),
            );

            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () =>
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                }),
            };
          }).reverse();
        case "weekly":
          return Array.from({ length: 4 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-1, "day").add(-i, "week"),
            );
            return {
              date:
                moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () =>
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                }),
            };
          }).reverse();
        case "monthly":
          return Array.from({ length: 3 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-i, "month"),
            );

            return {
              date:
                moment()
                  .add(-i, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () =>
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                }),
            };
          }).reverse();
      }
      return [];
    },
  },
};
</script>
