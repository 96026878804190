<template>
  <div>
    <input
      ref="reference"
      v-model="internalVisibleTime"
      type="text"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
      :class="$style.input"
    />
    <div
      ref="floating"
      v-if="showPopover"
      :class="$style.popover"
      :style="floatingStyles"
    >
      <div
        v-for="(_time, key) in times"
        :key="key"
        @click.prevent="
          () => {
            internalVisibleTime = $moment
              .utc($moment.utc(basis).format('YYYY-MM-DD') + ' ' + _time)
              .local()
              .format('HH:mm');
            duration = $moment
              .utc($moment.utc(basis).format('YYYY-MM-DD') + ' ' + _time)
              .diff($moment.utc(basis), 'seconds');
          }
        "
        :data-test="'time_picker_set_' + _time"
      >
        <slot name="timeSuggestion" :timeLocal="_time">
          <button :class="$style.buttonTimeSuggestion">
            {{ _time }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    basis: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      internalTime: "",
      internalVisibleTime: "",
      showPopover: false,
    };
  },
  watch: {
    duration(val) {
      this.internalDuration = val;
      this.calculateTime();
    },

    // eslint-disable-next-line no-unused-vars
    basis(val) {
      this.calculateTime();
    },
  },
  mounted() {
    this.internalDuration = this.time;
    this.calculateTime();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    times() {
      return Array.from({ length: 24 }, (_, hour) => {
        return ["00", "15", "30", "45"].map((minute) => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    },
  },
  methods: {
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    calculateTime() {
      this.internalVisibleTime = this.$moment
        .utc(this.basis)
        .add(this.duration, "seconds")
        .local()
        .format("HH:mm");
    },

    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      if (this.isValidTime(this.internalVisibleTime)) {
        this.duration = this.$moment(this.internalVisibleTime, "HH:mm").diff(
          this.$moment.utc(this.basis),
          "seconds",
        );
      }

      this.delayShowPopover(false);
    },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
  },
};
</script>

<style module lang="scss">
.input {
  composes: input from "@/styles/form.module.scss";

  font-variant-numeric: tabular-nums;

  width: 56px;
  text-align: center;

  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
}
.popover {
  composes: panel from "@/styles/panels.module.scss";
  max-height: 20rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--ps-base-background-color);
  z-index: 10;
}

.buttonTimeSuggestion {
  @apply flex items-center gap-1;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
