<template>
  <section :class="$style.scheduleDisplay">
    <!-- <Transition
      @enter="
        (el, done) => {
          $anime({
            targets: el,
            opacity: [0, 1],
            translateX: [-20, 0],
            complete: done,
          });
        }
      "
      :css="false"
    > -->
    <!-- <div v-if="isInitialized"> -->
    <div
      v-if="entry && entry.schedule"
      data-test="entry_schedule"
      @click.stop
      class="flex flex-wrap items-center gap-x-2 gap-y-1 text-neutral-500 dark:text-neutral-400"
      :class="{ 'pointer-events-none': disabled }"
    >
      <!-- <TransitionGroupHelper> -->
      <DatePicker
        key="date"
        ref="date"
        :entry="entry"
        :modelValue="schedule.date"
        :teleport="true"
        @update="updateDate"
        @update:recurrence="
          (value) => {
            schedule = { ...schedule, recurrence: value };
          }
        "
        @click.stop="
          (e) => $emit('click', e) // @note for input display to recognize click
        "
        :class="$style.datePicker"
        data-test="entry_schedule_date"
      />
      <TimePicker
        key="time"
        ref="time"
        :entry="entry"
        :otherentries="otherentries"
        :modelValue="schedule.time"
        :teleport="true"
        @update:modelValue="updateTime"
        @update:duration="
          (value) => {
            schedule = { ...schedule, duration: value };
          }
        "
        @click.stop="
          (e) => $emit('click', e) // @note for input display to recognize click
        "
        :class="$style.timePicker"
        data-test="entry_schedule_time"
      />
      <!-- </TransitionGroupHelper> -->
    </div>
    <ButtonComponent
      v-else
      type="button"
      @click="createAddon()"
      data-test="entry_schedule_create"
    >
      <IconHelper name="clock" size="16" /> <span>Schedule</span>
    </ButtonComponent>
    <!-- </div> -->
    <!-- </Transition> -->
  </section>
</template>

<script>
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";

export default {
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: Object,
    entry: Object,
    otherentries: Array,
    disabled: Boolean,
  },
  // data() {
  //   return { isInitialized: false };
  // },

  mounted() {
    // this.$onIdle(() => {
    //   this.isInitialized = true;
    // });
    // console.log("ScheduleDisplay::mounted()", this.entry.name);
  },
  computed: {
    schedule: {
      get() {
        return this.entry.schedule;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = value;
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          });
      },
    },
  },
  watch: {
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule.date": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
      }
    },
    "entry.schedule.time": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.time = this.entry.schedule?.time;
      }
    },
  },
  methods: {
    updateDate(val) {
      //this.updateObject(this.entry, (entry) => {
      if (this.entry.schedule === null) return;

      if (val.op && val.op === "null" && this.entry.schedule?.time === null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.interpretDate({ date: val }),
          time: this.entry.schedule?.time,
        });
        //});
      }
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
    },
    updateTime(val) {
      if (this.entry.schedule === null) return;

      if (
        (val === null || (val && val.op && val.op === "null")) &&
        this.entry.schedule?.date === null
      ) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.entry.schedule?.date,
          time: this.interpretTime({ time: val }),
        });
      }

      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
    },
  },
};
</script>

<style module lang="scss">
.scheduleDisplay {
  composes: entrySubRow from "@/styles/entries.module.scss";
  // composes: scheduleDisplay from "@/styles/schedules.module.scss";
}

@container calendarEventContent (width < 96px) {
  /* this will hide the date when the width is less than 100px */
  .datePicker,
  .timePicker {
    display: none;
  }

  @container calendarEventContent (height < 64px) {
    /* This will hide the schedule display in the calendar view  */
    .scheduleDisplay {
      display: none;
    }
  }
}

@container calendarEventContent (height < 96px) {
  /* This will hide the schedule display in the calendar view  */
  .datePicker {
    display: none;
  }
}
@container calendarEventContent (height < 48px) {
  /* This will hide the schedule display in the calendar view  */
  .scheduleDisplay {
    display: none;
  }
}
</style>
