<template>
  <div>
    <slot :date="date">
      <PopoverHelper
        id="date_picker"
        :teleport="teleport"
        class="relative"
        data-test="date_picker"
      >
        <template #button>
          <ButtonComponent
            @click="
              (e) => {
                autoSetOnClick === true
                  ? entry
                    ? typeof date != 'string' && date !== null
                      ? (date = $moment.utc().format('YYYY-MM-DD'))
                      : null
                    : typeof date == 'undefined' ||
                      date === null ||
                      date.length === 0
                    ? (date = { op: 'today' })
                    : null
                  : null;
                // $emit('click', e);
              }
            "
            variant="none"
            ref="reference"
            :class="{
              'font-semibold':
                $formattedDateOp(date) == 'today' ||
                ($formattedDateOp(date) == 'yesterday' &&
                  !statusIsDone(entry?.status)),
              '!text-neutral-700 hover:!text-black dark:!text-neutral-400 dark:hover:!text-neutral-300':
                $formattedDateOp(date) == 'today',
              '!text-red-500 hover:!text-red-600 dark:!text-red-600 dark:hover:!text-red-500':
                $formattedDateOp(date) == 'yesterday' &&
                entry?.status &&
                !statusIsDone(entry?.status),
            }"
            class="group inline-flex items-center gap-1 whitespace-nowrap uppercase text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
            data-test="datepicker_button"
          >
            <CalendarIcon size="14" />
            <span :data-date="date">{{ displayText }}</span>
            <!-- <SmoothText :text="displayText" :data-date="date" /> -->
            <ArrowPathIcon v-if="entry?.schedule?.recurrence" class="h-4 w-4" />
          </ButtonComponent>
        </template>

        <div
          class="z-40 max-h-screen min-w-fit overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:text-neutral-400 dark:ring-neutral-700"
          data-test="date_picker_config"
        >
          <div v-if="typeof date != 'object' || date === null">
            <PopoverButton
              @click="date = $moment().format('YYYY-MM-DD')"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
              data-test="date_picker_set_today"
            >
              <span style="font-weight: bold"> Today </span>
              <span>
                {{ $formattedDateOp($moment().format("YYYY-MM-DD"), false) }}
              </span>
            </PopoverButton>

            <PopoverButton
              @click="date = $moment().add(1, 'days').format('YYYY-MM-DD')"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
              data-test="date_picker_set_tomorrow"
            >
              <span style="font-weight: bold"> Tomorrow </span>
              <span>
                {{
                  $formattedDateOp(
                    $moment().add(1, "days").format("YYYY-MM-DD"),
                    false,
                  )
                }}
              </span>
            </PopoverButton>
            <PopoverButton
              @click="date = $moment().add(-1, 'days').format('YYYY-MM-DD')"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
              data-test="date_picker_set_yesterday"
            >
              <span style="font-weight: bold"> Yesterday </span>
              <span>
                {{
                  $formattedDateOp(
                    $moment().add(-1, "days").format("YYYY-MM-DD"),
                    false,
                  )
                }}
              </span>
            </PopoverButton>

            <PopoverButton
              v-if="entry || date"
              @click.prevent="date = { op: 'null' }"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
              data-test="date_picker_unset"
            >
              <span style="font-weight: bold"> Unset </span>
              <TrashIcon class="h-5 w-5" />
            </PopoverButton>
            <CalendarComponent v-slot="{ date: _date } = slotProps">
              <button
                @click.prevent="date = _date"
                :class="{
                  'font-bold text-black':
                    _date == $moment().format('YYYY-MM-DD'),
                  'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white':
                    _date == entry?.schedule?.date,
                }"
                :data-test="'date_picker_set_' + _date"
              >
                {{ $moment(_date).format("DD") }}
              </button>
            </CalendarComponent>

            <small
              v-if="showRecurrence"
              class="px-3 py-1"
              style="display: block; text-align: center"
            >
              Recurrence
            </small>
            <div
              v-if="showRecurrence"
              class="max-h-32 overflow-y-auto py-1"
              style="scrollbar-gutter: stable"
              data-test="date_picker_recurrence"
            >
              <PopoverButton
                v-for="(option, index) in recurrenceOptions"
                :key="index"
                @click="$emit('update:recurrence', option.value)"
                :class="
                  recurrence?.op == option.value.op &&
                  recurrence?.x == option.value.x
                    ? 'font-bold'
                    : null
                "
                class="flex w-full justify-between px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
                :data-test="'recurrence_set_' + $slugify(option.title)"
              >
                <span style="font-weight: medium">
                  {{ option.title }}
                </span>
                <span
                  style="
                    color: var(--color-medium-gray);
                    font-size: var(--font-size-smaller);
                  "
                >
                  &orarr;
                  {{ interpretDate({ date: option.value }) }}
                </span>
              </PopoverButton>
            </div>

            <PopoverButton
              v-if="entry?.schedule?.recurrence"
              @click="$emit('update:recurrence', null)"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
            >
              <span style="medium"> Unset Recurrence </span>
              <span style="color: var(--color-medium-gray)"> </span>
            </PopoverButton>
          </div>

          <div v-else-if="typeof date == 'object'" class="p-3">
            <div
              style="display: flex; justify-content: space-between; gap: 1rem"
            >
              <span
                v-if="date?.op"
                :style="{
                  color:
                    date.op == 'today' ||
                    date.op == 'tomorrow' ||
                    (date.op.slice(0, 4) == 'next' &&
                      (typeof date.x == 'undefined' || date.x >= 0)) ||
                    (date.op.slice(0, 4) == 'last' &&
                      (typeof date.x == 'undefined' || date.x <= 0))
                      ? 'var(--color-dark-gray)'
                      : 'var(--color-medium-gray)',
                }"
                style="
                  font-size: 82%;
                  font-weight: bold;
                  text-transform: uppercase;
                "
              >
                in
              </span>
              <span style="display: flex; gap: 0.5rem">
                <button
                  @click.prevent="modifyRelativeDate(-1, date)"
                  data-test="datepicker_relative_number_minus"
                >
                  &minus;
                </button>
                <span style="font-weight: bold">{{
                  relativeTimespan(date)
                    ? Math.abs(relativeTimespan(date)[0])
                    : null
                }}</span>
                <button
                  @click.prevent="modifyRelativeDate(1, date)"
                  data-test="datepicker_relative_number_plus"
                >
                  &plus;
                </button>
              </span>
              <span style="display: flex; gap: 0.5rem">
                <button @click.prevent="modifyRelativeTimespan(-1, date)">
                  &minus;
                </button>
                <button style="font-weight: bold; text-transform: uppercase">
                  {{
                    relativeTimespan(date) ? relativeTimespan(date)[1] : null
                  }}
                </button>
                <button @click.prevent="modifyRelativeTimespan(1, date)">
                  &plus;
                </button>
              </span>
              <span
                v-if="date?.op"
                :style="{
                  color:
                    date.op == 'yesterday' ||
                    (date.op.slice(0, 4) == 'next' &&
                      (typeof date.x == 'undefined' || date.x < 0)) ||
                    (date.op.slice(0, 4) == 'last' &&
                      (typeof date.x == 'undefined' || date.x > 0))
                      ? 'var(--color-dark-gray)'
                      : 'var(--color-medium-gray)',
                }"
                style="
                  font-size: 82%;
                  font-weight: bold;
                  text-transform: uppercase;
                "
              >
                ago
              </span>
            </div>

            <ButtonComponent
              v-if="entry"
              @click.prevent="
                [(date = entry ? { op: 'null' } : { op: 'unset' })]
              "
              title="unset the schedule date"
            >
              <TrashIcon class="h-5 w-5" />
              <span> Unset </span>
            </ButtonComponent>
          </div>
        </div>
      </PopoverHelper>
    </slot>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverOverlay,
} from "@headlessui/vue";

import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
  placement: "top-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
import CalendarComponent from "../helpers/CalendarComponent.vue";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
// eslint-disable-next-line no-unused-vars
import { ArrowPathIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { CalendarIcon } from "lucide-vue-next";
export default {
  // inject: ["position"],
  name: "DatePicker",
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
    showRecurrence: {
      type: Boolean,
      default: true,
    },
    autoSetOnClick: {
      type: Boolean,
      default: true,
    },
    teleport: Boolean,
  },
  data() {
    return {
      cursor: this.modelValue || this.$moment().format("YYYY-MM-DD"),
      recurrenceOptions: [
        { title: "Every Day", value: { op: "nextXDays", x: 1 } },
        { title: "Every 2 Days", value: { op: "nextXDays", x: 2 } },
        { title: "2x / Week", value: { op: "nextXWeeks", x: 0.5 } },
        { title: "Every Week", value: { op: "nextXWeeks", x: 1 } },
        { title: "Every 2 Weeks", value: { op: "nextXWeeks", x: 2 } },
        { title: "Every Month", value: { op: "nextXMonths", x: 1 } },
        { title: "Every 2 Months", value: { op: "nextXMonths", x: 2 } },
        { title: "Every Quarter", value: { op: "nextXMonths", x: 3 } },
        { title: "2x / Year", value: { op: "nextXYears", x: 0.5 } },
        { title: "Every Year", value: { op: "nextXYears", x: 1 } },
      ],
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.cursor = this.modelValue || this.$moment().format("YYYY-MM-DD");
      },
      deep: true,
    },
  },
  computed: {
    recurrence() {
      return this.entry?.schedule?.recurrence;
    },
    date: {
      get() {
        return this.modelValue;
      },
      set(date) {
        // console.log("date", date);
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      },
    },
    displayText() {
      return this.entry || this.date
        ? this.$formattedDateOp(this.date)
        : "Set Date";
    },

    dayOptions() {
      return Array.from(
        { length: this.$moment(this.cursor).local().daysInMonth() },
        (_, i) => i + 1,
      ).map((day) => {
        const _date = this.$moment(
          this.$moment(this.cursor).set("date", day),
        ).format("YYYY-MM-DD");
        return {
          title: this.$moment(_date).date().toString().padStart(2, "0") /* +
                          (_date == date ? " · " + this.$moment(_date).format("ddd") : "")*/,
          date: _date,
          active: _date == this.modelValue,
        };
      });
    },
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { CalendarIcon, CalendarComponent, TrashIcon },
};
</script>
