<template>
  <div
    v-if="schema"
    @click="editing = true"
    :class="{
      [$style.inputDisplay]: true,
      // 'rounded-lg px-2 py-1.5': !['gallery'].includes(renderType),
      // 'hover:bg-neutral-50 dark:hover:bg-neutral-950':
      //   !['gallery'].includes(renderType) && !disabled,
      [$style.inputDisplay_disabled]: disabled,
      // '-mx-2 -my-1.5 ': position != 'left' && !['gallery'].includes(renderType),
      [$style.inputDisplay_editing]: editing,
    }"
    data-test="entry_input_section"
  >
    <div class="min-h-8">
      <!-- This is for reseting the keyboard suggestions on IPhone and maybe other mobile devices-->
      <input
        type="text"
        ref="mobile-suggestion-fix"
        class="absolute h-0 w-0 opacity-0"
      />
      <EntryDisplay
        ref="entry_display"
        :modelValue="newEntry"
        :placeholder="
          '&plus; ' +
          (entry && entry.name
            ? entry.name.length > 40
              ? entry.name.slice(0, 37) + '...'
              : entry.name
            : 'Add Entry')
        "
        :display="display"
        :renderType="renderType"
        :position="position"
        :disabled="entryDisplayIsDisabled"
        :editable="position != 'template'"
        @onNameKeyDown="(name, event) => handleOnEntryNameKeyDown(event, name)"
        @onNameKeyUp="(name, event) => handleOnEntryNameKeyUp(event, name)"
        @onNameChanged="createEntry"
        @onFocus="editing = true"
        @click="editing = true"
        :data-input="entry.id"
        :data-test="$slugify(entry?.name) + '_input'"
        data-tour="input_display"
      >
        <template v-slot:top-right>
          <TourHelper
            v-if="
              entry.id &&
              entry.id == $route.params.id &&
              editable &&
              position == 'center'
            "
            hook="input_settings"
            size="sm"
            variant="minimal"
            class="mr-1.5 mt-0.5 h-5"
          />

          <PopoverHelper
            v-if="editable"
            id="input_builder"
            data-test="input_builder"
            class="mt-0.5"
            :teleport="true"
          >
            <template v-slot:button>
              <span
                class="w-5 text-neutral-400 hover:text-black dark:text-neutral-600 dark:hover:text-neutral-300"
                data-tour="input_settings"
              >
                <IconHelper name="settings-2" size="20" />
              </span>
            </template>

            <div class="flex flex-col gap-4 p-3">
              <div
                v-if="Object.keys(overrides).length > 0"
                class="rounded-md bg-blue-50 p-4"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      The following fields have been overridden:

                      <span
                        v-for="key in Object.keys(overrides)"
                        :key="key"
                        class="text-xs font-bold uppercase"
                      >
                        {{ key }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <InputSettings
                id="input_builder_inner"
                v-model="input"
                :entry="entry"
                @click.stop
                class="w-96"
              />
            </div>
          </PopoverHelper>
        </template>
      </EntryDisplay>

      <component :is="'style'">
        .{{ $style.inputDisplay }} [data-entry-id="{{
          currentSearchResults[searchFocusIndex]?.id
        }}"] { --ps-output-display-entry-display-background-color:
        var(--ps-base-tinted-color); }
      </component>

      <div ref="reference" v-if="editing" :class="$style.toolBar">
        <ButtonComponent
          @click.stop="resetEntry(), (editing = false), $emit('onCancel')"
          variant="minimal"
          size="sm"
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          @click.stop="
            () => {
              createEntry(searchQuery);
              editing = false;
            }
          "
          :color="color || 'neutral'"
          :colorWeight="!color || color == 'neutral' ? '900' : '500'"
          title="Create Entry"
        >
          <span> Add Entry </span>
        </ButtonComponent>
      </div>
    </div>
    <Teleport to="body">
      <Transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform opacity-0 translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0 translate-y-2"
      >
        <div v-if="showSearch" class="absolute z-40">
          <SearchResults
            ref="search_results"
            :query="searchQuery"
            :canHaveNoFocus="true"
            :limit="5"
            @onEntrySelect="handleOnEntrySelect"
            @onSearchResultsChange="currentSearchResults = $event"
            class="w-64"
            :style="floatingStyles"
          />
        </div>
      </Transition>
    </Teleport>
  </div>
  <div v-else>
    <ButtonComponent
      @click="input = addonBlueprint('input', entry, this)"
      data-test="entry_input_create"
      class="w-full"
    >
      <IconHelper name="TextCursorInput" size="20" />
      <span> Add Input </span>
    </ButtonComponent>
  </div>
</template>

<script setup>
import { ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { autoUpdate, useFloating } from "@floating-ui/vue";

const reference = ref(null);
const search_results = ref(null);

const { floatingStyles } = useFloating(reference, search_results, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [],
});
</script>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { Searching } from "../mixins/Searching";
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
  },
  props: {
    focusOnMount: Boolean,
    modelValue: Object,
    entry: Object,
    schema: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    display: Object,
    position: {
      type: String,
      default: () => {
        return "center";
      },
    },
    renderType: {
      type: String,
      default: () => {
        return "list";
      },
    },
    color: {
      type: String,
      default: () => {
        return "neutral";
      },
    },
  },
  mixins: [Schema, Statusing, Scheduling, Searching, Applicators, DragAndDrop],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    InformationCircleIcon,
  },
  data() {
    return {
      editing: false,
      newEntry: this.inputSchemaWithId(this.schema),
      scheduleTimer: null,
      output: [],
      focusIndex: 0,
      registeredInputId: null,
      addonBlueprint: addonBlueprint,

      // SEARCH RELATED

      showSearchTimeout: null,
      showSearch: false,
      searchQuery: "",
      searchFocusIndex: -1,
      currentSearchResults: [],
    };
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    overrides() {
      const overrides = {};
      Object.keys(this.schema).forEach((key) => {
        if (
          JSON.stringify(this.schema[key]) !==
          JSON.stringify(this.entry.input.data[key])
        ) {
          overrides[key] = this.schema[key];
        }
      });
      return overrides;
    },
    entryDisplayIsDisabled() {
      return this.position == "template";
    },
  },
  mounted: function () {
    this.manageInputRegister();
    if (this.focusOnMount)
      setTimeout(() => {
        this.editing = true;
      }, 10);
  },
  beforeUnmount() {
    this.manageInputRegister(false);
  },
  watch: {
    newEntry: {
      handler() {
        // console.log(this.newEntry.name);
        // console.trace();
        // console.log("newEntry changed", this.newEntry.name);
      },
      deep: true,
    },
    searchQuery: function (n) {
      if (n.length > 0) {
        if (this.showSearch === false && this.position != "left") {
          if (this.showSearchTimeout) clearTimeout(this.showSearchTimeout);
          this.showSearchTimeout = setTimeout(() => {
            this.showSearch = true;
          }, 2000);
        }
      } else {
        this.showSearch = false;
      }
    },
    space: function () {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    "$route.params.id": {
      handler(n, o) {
        if (n != o) {
          this.resetEntry();
        }
      },
      immediate: true,
    },
    editing: function (n) {
      // Select the input field when editing
      if (n) {
        if (this.editing && this.disabled) {
          this.editing = false;
        }
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.querySelector("[contenteditable='true']")?.focus();
            this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
      }
    },
    // entries: function () {
    //   this.newEntry = this.inputSchemaWithId(this.schema);
    // },
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.editing = false;
        this.newEntry = this.inputSchemaWithId(this.schema);
        this.manageInputRegister();
      }
    },
    input: function () {
      if (this.input) this.newEntry = this.inputSchemaWithId(this.schema);
    },
    schema: {
      handler() {
        // if (JSON.stringify(n) != JSON.stringify(o)) {
        this.newEntry = this.inputSchemaWithId(this.schema);
        // }
      },
      deep: true,
    },
    "$store.getters.clock": function () {
      this.scheduleUpdater();
    },
    searchFocusIndex: function (n, o) {
      /**
       * We want to bring the new focused
       */
      this.$nextTick(() => {
        const element =
          this.$refs.search_results?.$refs.output?.$refs["entry_" + n];
        if (element && element[0]) {
          element[0].scrollIntoView({
            /**
             * If the difference between the old and new index is 1, scroll smoothly.
             * Otherwise, we move fast fro top to bottom or vice versa and just jump to the element.
             */
            behavior: Math.abs(n - o) == 1 ? "smooth" : "auto",
            block: "center",
            inline: "center",
          });
        }
      });
    },
  },
  methods: {
    onKeyDown(e) {
      switch (e.key) {
        case "Tab":
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          this.incrementFocusIndex();
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          this.decrementFocusIndex();
          break;
      }
    },
    incrementFocusIndex() {
      if (this.currentSearchResults.length) {
        // If the search focus index is less than the length of the search results
        if (this.searchFocusIndex < this.currentSearchResults.length - 1) {
          // Increment the search focus index
          this.searchFocusIndex++;
        } else {
          // Otherwise set the search focus index to 0
          this.searchFocusIndex = 0;
        }
      }
    },
    decrementFocusIndex() {
      if (this.currentSearchResults.length) {
        // If the search focus index is greater than 0
        if (this.searchFocusIndex > 0 ? -1 : 0) {
          // Decrement the search focus index
          this.searchFocusIndex--;
        } else {
          // Otherwise set the search focus index to the length of the search results
          this.searchFocusIndex = this.currentSearchResults.length - 1;
        }
      }
    },
    handleOnEntryNameKeyDown(event) {
      // if (event.key == "Enter") {
      //   this.createEntry();
      // }
      // console.log("handleOnEntryNameKeyDown", event.key, name);
      this.onKeyDown(event);
      // this.searchQuery = name;
    },
    handleOnEntryNameKeyUp(event, name) {
      if (event.key == "Enter") {
        if (this.searchFocusIndex >= 0) {
          this.handleOnEntrySelect(
            this.currentSearchResults[this.searchFocusIndex],
          );
        } else {
          this.createEntry(name);
        }
      } else {
        this.searchQuery = name;
      }
    },
    handleOnEntrySelect(entry) {
      this.applyInput(this.inputSchema(this.schema, entry), entry, true);
      this.newEntry.name = entry.name;
      this.resetEntry();
      setTimeout(this.clearNameInputTextField, 0);
    },
    clearNameInputTextField() {
      {
        if (
          this.$refs.entry_display?.$refs.name_display.$refs.text_input.$refs
            .input
        ) {
          // console.log("InputDisplay::resetEntry");
          this.$refs.entry_display.$refs.name_display.$refs.text_input.$refs.input.innerText =
            "";
        }
      }
    },
    createEntry(name) {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      if (this.editing && regex.test(navigator.userAgent)) {
        /**
         * This is a hack to fix the issue where
         * the keyboard suggestions do not reset
         * on mobile devices after creating an entry.
         */
        const focus = this.$el.querySelector('[contenteditable="true"]:focus');
        if (focus) {
          this.$refs["mobile-suggestion-fix"].focus();
          // setTimeout(() => {
          this.$el.querySelector('[contenteditable="true"]')?.focus();
          this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          // }, 200);
        }
      }

      // This got obsolete when Unnamed entries were introduced
      // if (this.newEntry.name.length == 0) return;

      const newEntry = JSON.parse(JSON.stringify(this.newEntry));

      delete newEntry.temp;
      newEntry.name = name;
      newEntry.created_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");
      const emitEntry = { ...newEntry };

      // Seriously I like the push more, but its anti-pattern
      this.$store.dispatch("addEntry", newEntry);
      // console.log("newEntry", newEntry.name);
      // this.$store.getters.entries.push(this.newEntry);
      // console.log(this.newEntry);
      this.$store.dispatch("push", {
        event: "entry_create",
        params: { entry: newEntry },
        entry: newEntry,
        // cb: () => this.$emit("created", emitEntry),
      });

      this.resetEntry();
      this.$emit("created", emitEntry);

      // In case the input is on the left side, we want to close the input after creating an entry
      if (this.position == "left") {
        this.editing = false;
        this.showSearch = false;
      } else {
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
            this.$el.querySelector('[contenteditable="true"]')?.focus();
          }
        });
      }
    },
    resetEntry() {
      this.newEntry = this.inputSchemaWithId(this.schema);

      this.clearNameInputTextField();
      setTimeout(() => {
        /**
         * This is a hack to fix the issue where
         * the input field does not clear after creating an entry.
         * This is only an issue on mobile devices.
         */
        this.clearNameInputTextField();
      }, 0);

      this.searchQuery = "";
      this.showSearch = false;
      this.searchFocusIndex = -1;
    },
    /**
     * Updates the schedule of the new entry based on the schema's schedule.
     * If the schema has a schedule date and time, it interprets them and sets them on the new entry.
     */
    scheduleUpdater() {
      // Check if the schema and new entry have a schedule
      if (this.schema?.schedule && this.newEntry?.schedule) {
        // Check if the schema has a schedule date and the new entry has a schedule date
        if (this.schema.schedule.date?.op && this.newEntry.schedule.date) {
          // Interpret the date from the schema and set it on the new entry
          this.newEntry.schedule.date = this.interpretDate(
            this.schema.schedule,
          );
        }
        // Check if the schema has a schedule time and the new entry has a schedule time
        if (this.schema.schedule.time?.op && this.newEntry.schedule.time) {
          // Interpret the time from the schema and set it on the new entry
          this.newEntry.schedule.time = this.interpretTime(
            this.schema.schedule,
          );
        }
      }
    },
    onInputUpdate() {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    inputSchemaWithId(schema) {
      const base = {
        temp: true,
        id: nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        status_id: null,
        name: null,
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        backlinks: [],
        created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null,
      };
      return {
        ...base,
        ...this.inputSchema(schema, base),
      };
    },
    manageInputRegister(add = true) {
      if (this.entry.id && this.position == "center") {
        if (this.registeredInputId) {
          this.$store.dispatch("unregisterInput", {
            id: this.registeredInputId,
            el: this.$el,
            entry: this.entry,
            schema: this.schema,
          });
          this.registeredInputId = null;
        }
        if (add) {
          this.registeredInputId = this.$nanoid();
          this.$store.dispatch("registerInput", {
            id: this.registeredInputId,
            el: this.$el,
            obj: this,
            entry: this.entry,
            schema: this.schema,
          });
        }
      }
    },
  },
};
</script>

<style module>
.inputDisplay {
  @apply relative flex cursor-pointer flex-col gap-y-2;
  border-radius: var(--ps-entry-display-border-radius);
}

.inputDisplay_disabled {
  @apply !cursor-default;
}
.inputDisplay_editing {
  background-color: var(--ps-base-tinted-color);
  outline: 1px solid var(--ps-base-border-color);
  outline-offset: -1px;
}

.toolBar {
  @apply flex justify-end gap-x-4 p-2;
  border-top: 1px solid var(--ps-base-border-color);
}
</style>
