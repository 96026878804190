<template>
  <PopoverHelper>
    <template #button>
      <div :class="$style.button">
        {{ $moment(date).format("DD.MM.YYYY") }}
      </div>
    </template>
    <template v-slot="{ close }">
      <CalendarComponent
        v-slot="{ date: _date } = slotProps"
        :class="$style.calendar"
      >
        <button
          @click.prevent="(date = _date), close()"
          :class="{
            'font-bold text-black': _date == $moment().format('YYYY-MM-DD'),

            'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white':
              _date == date,
          }"
          :data-test="'date_picker_set_' + _date"
        >
          {{ $moment(_date).format("DD") }}
        </button>
      </CalendarComponent>
    </template>
  </PopoverHelper>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style module lang="scss">
.button {
  composes: input from "@/styles/form.module.scss";
  font-variant-numeric: tabular-nums;
}
.calendar {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
