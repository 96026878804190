<template>
  <button
    ref="reference"
    type="button"
    :disabled="disabled"
    :class="$style.buttonComponent"
    class="group/button"
    data-hide-from-share
  >
    <slot />
    <slot name="title">
      <div
        ref="floating"
        v-if="title && !navigator.userAgent.includes('Mobile')"
        :style="floatingStyles"
        :class="
          nested
            ? 'group-hover/nested:opacity-100 group-hover/nested:transition-opacity group-hover/nested:delay-300'
            : 'group-hover/button:opacity-100 group-hover/button:transition-opacity group-hover/button:delay-300'
        "
        class="pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 text-ellipsis whitespace-nowrap rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 shadow-md dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"
      >
        <span v-html="title" />
        <kbd
          v-if="shortcode"
          class="inline-flex h-5 w-5 items-center justify-center rounded border border-neutral-400 bg-white font-semibold text-neutral-900 dark:border-neutral-600 dark:bg-black dark:text-neutral-200"
        >
          {{ shortcode }}
        </kbd>
      </div>
    </slot>

    <!-- <OverlayComponent v-if="help" @click.stop="help = false" />
    <div
      v-if="help"
      @click.stop
      class="absolute bottom-0 left-0 z-40 mx-auto w-max min-w-full translate-y-full cursor-default overflow-visible rounded-md bg-white px-2 py-1 text-left text-sm text-neutral-700 drop-shadow-md"
    >
      <slot name="help" />

      <div class="absolute right-0 top-0 -translate-y-1/3 translate-x-1/3">
        <ButtonComponent @click.stop="help = false" variant="round">
          <XMarkIcon class="w-5" />
        </ButtonComponent>
      </div>
    </div> -->
  </button>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-center",
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(2),
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
// import { CircleHelp } from "lucide-vue-next";
export default {
  components: {
    // CircleHelp,
  },
  props: {
    disabled: Boolean,
    color: {
      type: String,
      default: "neutral",
    },
    colorWeight: {
      type: String,
      default: "500",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "default",
    },
    active: Boolean,
    nested: Boolean,
    title: String,
    shortcode: String,
  },
  data() {
    return {
      navigator: navigator,
      help: false,
    };
  },
  computed: {
    colorClasses() {
      const classes = [];

      if (this.color == "primary") {
        classes.push(
          "bg-neutral-900 text-white dark:bg-neutral-100 dark:text-black ",
        );
        if (!this.disabled)
          classes.push("hover:bg-neutral-950 dark:hover:bg-neutral-50");
      } else if (this.color == "secondary") {
        classes.push(
          "bg-white text-black dark:bg-black dark:text-neutral-200 ring-1 ring-neutral-200 dark:ring-neutral-700",
        );
        if (!this.disabled)
          classes.push("hover:bg-neutral-50 dark:hover:bg-neutral-950");
      } else if (this.color == "white") {
        classes.push("bg-white text-black dark:bg-black dark:text-white ");
        if (!this.disabled)
          classes.push(
            "hover:bg-neutral-50 hover:ring-1 hover:ring-neutral-200 dark:hover:bg-neutral-950",
          );
      } else if (this.color == "black") {
        classes.push("bg-black text-white dark:bg-white dark:text-black");
        if (!this.disabled)
          classes.push(
            "hover:bg-neutral-950 hover:ring-1 hover:ring-neutral-200 dark:hover:bg-neutral-50",
          );
      } else {
        classes.push(`bg-${this.color}-${this.colorWeight}`);
        classes.push(
          `text-${this.colorWeight >= 500 ? "white" : "black"} dark:text-${
            this.color
          }-100`,
        );
        if (!this.disabled)
          classes.push(
            `hover:bg-${this.color}-${
              this.colorWeight >= 500 ? "600" : "400"
            } hover:ring-1 hover:ring-${this.color}-200 dark:hover:bg-${
              this.color
            }-${this.colorWeight >= 500 ? "900" : "700"}`,
          );
      }
      return classes.join(" ");
    },
    classes() {
      switch (this.variant) {
        case "menu":
          switch (this.size) {
            // case "xs":
            //   return `inline-flex items-center gap-x-1 text-xs font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "sm":
              return `inline-flex items-center gap-x-2 px-2 py-1 text-sm text-${
                this.color
              }-600 hover:text-${
                this.color
              }-800 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200 ${
                this.active
                  ? `bg-${
                      this.color == "white" ? "neutral" : this.color
                    }-100 dark:bg-${
                      this.color == "white" ? "neutral" : this.color
                    }-900`
                  : null
              }`;
            case "md":
            default:
              return `inline-flex items-center gap-x-2 px-2 py-1 text-md text-${
                this.color
              }-600 hover:text-${
                this.color
              }-800 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200 ${
                this.active
                  ? `bg-${
                      this.color == "white" ? "neutral" : this.color
                    }-100 dark:bg-${
                      this.color == "white" ? "neutral" : this.color
                    }-900`
                  : null
              }`;
          }
        case "minimal":
          switch (this.size) {
            case "xs":
              return `inline-flex items-center gap-x-1 text-xs font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "sm":
              return `inline-flex items-center gap-x-1 text-sm font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "md":
            default:
              return `inline-flex items-center gap-x-2 text-md font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
          }
        case "round":
          switch (this.size) {
            case "xs":
              return `${this.colorClasses} inline-flex items-center gap-x-2
                rounded-full p-0.5
                text-white shadow-sm hover:bg-${this.color}-500
                focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-900`;
            case "sm":
              return `${this.colorClasses} inline-flex items-center gap-x-2
                rounded-full p-1
                shadow-sm hover:bg-${this.color}-500
                focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-900`;
            case "lg":
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full p-2
              shadow-sm hover:bg-${this.color}-500
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-900`;
            case "xl":
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full p-3
              shadow-sm hover:bg-${this.color}-500
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-900`;
            case "md":
            default:
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full  p-1.5
               shadow-sm
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-600`;
          }
        case "default":
          switch (this.size) {
            case "xs":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded px-2 py-1 text-xs font-semibold 
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
            case "sm":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded px-2 py-1 text-sm font-semibold
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;

            case "lg":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded-md px-3 py-2 text-sm font-semibold shadow-sm
              focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
            case "md":
            default:
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded-md px-2.5 py-1.5 text-sm font-semibold  shadow-sm
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
          }
      }
      return "";
    },
  },
};
</script>

<style module lang="scss">
.buttonComponent {
  composes: button from "@/styles/buttons.module.scss";
}
</style>
